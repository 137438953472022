<template>
  <div class="box-card">
    <!-- 人脸识别一体机 -->
    <div>
      <!-- 查询表单 -->
      <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm"
        @submit.native.prevent>
        <el-form-item label="序列号：">
          <el-input v-model="queryForm.device_face_serial" maxlength="30" @keyup.enter.native="onSubmit"
            @input="(e) => (queryForm.device_face_serial = validFacility(e))" placeholder="请输入序列号"></el-input>
        </el-form-item>
        <el-form-item label="设备名称：">
          <el-input v-model="queryForm.device_face_name" maxlength="30" @keyup.enter.native="onSubmit"
            @input="(e) => (queryForm.device_face_name = validSpace(e))" placeholder="请输入设备名称"></el-input>
        </el-form-item>
        <el-form-item label="状态：">
          <el-select popper-class="my-select" placeholder="请选择设备状态" clearable v-model="queryForm.device_state"
            @change="onSubmit" @clear="queryForm.device_state = null">
            <!-- <el-option label="全部" :value="0"></el-option> -->
            <el-option label="在线" :value="1"></el-option>
            <el-option label="离线" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
        </el-form-item>
      </el-form>
      <le-card title="人脸识别一体机">
        <template slot="heard-right">
          <div class="right">
            <lbButton type="err" icon="shanchu1" @click="batchDel" v-if="isShowBtn('63199658cc7f0000ae0058ce')">删除
            </lbButton>
            <lbButton icon="xinzeng" @click="openAddForm()" v-if="!isAdmin && isShowBtn('63199658cc7f0000ae0058cc')">新增
            </lbButton>
          </div>
        </template>
        <!-- 主要内容 -->
        <div style="text-align:left;">
          <!-- 表格 -->
          <!-- 使用插件生产表格 -->
          <!-- <avue-crud :data="tableData" :option="option" :page.sync="page" @selection-change="selectionChange"
            @size-change="sizeChange" @current-change="currentChange">
            <template slot-scope="{row}" slot="device_state">
              <div :style="row.device_state == 2 ? 'color: #ff0000;' : 'color: #18943b;'">
                {{ row.device_state == 2 ? "离线" : "在线" }}
              </div>
            </template>
            <template slot-scope="{row}" slot="create_time">
              {{ $moment(row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
            <template slot-scope="{row}" slot="menu">
              <lbButton type="succes" icon="chakan" hint="查看" @click="lookFaceRecognition(row._id)"></lbButton>
              <lbButton type="warning" icon="bianji" hint="编辑" @click="openEditForm(row)"
                v-if="isShowBtn('63199658cc7f0000ae0058cd')"></lbButton>
              <lbButton type="setting" icon="shebeipeizhi" hint="配置" @click="editFaceRecognition(row._id)"
                v-if="isShowBtn('63199658cc7f0000ae0058cf') && (row.device_face_model_name.indexOf('VM-F4.3') != -1 || row.device_face_model_name.indexOf('VM-F5') != -1)">
              </lbButton>
              <lbButton type="info" icon="sousuo" hint="预览" @click="handlePreview(row)"
                v-if="isShowBtn('63199658cc7f0000ae0058d0') && row.MainId"></lbButton>
            </template>
          </avue-crud> -->
          <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" border stripe
            @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="50" align="center" header-align="center"></el-table-column>
            <el-table-column prop="device_face_serial" label="序列号"></el-table-column>
            <el-table-column prop="device_face_name" label="设备名称"></el-table-column>
            <el-table-column prop="device_face_brand_name" label="设备品牌"></el-table-column>
            <el-table-column prop="device_face_model_name" label="设备型号"></el-table-column>
            <el-table-column label="状态" width="80">
              <template slot-scope="scope">
                <div :style="scope.row.device_state == 2 ? 'color: #ff0000;' : 'color: #18943b;'">
                  {{ scope.row.device_state == 2 ? "离线" : "在线" }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="创建时间" width="180">
              <template slot-scope="scope">
                {{ $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
              </template>
            </el-table-column>
            <el-table-column label="操作" width="200">
              <template slot-scope="scope">
                <lbButton type="succes" icon="chakan" hint="查看" @click="lookFaceRecognition(scope.row._id)"></lbButton>
                <lbButton type="warning" icon="bianji" hint="编辑" @click="openEditForm(scope.row)"
                  v-if="isShowBtn('63199658cc7f0000ae0058cd')"></lbButton>
                <lbButton type="setting" icon="shebeipeizhi" hint="配置" @click="editFaceRecognition(scope.row._id)"
                  v-if="isShowBtn('63199658cc7f0000ae0058cf') && (scope.row.device_face_model_name.indexOf('VM-F4.3') != -1 || scope.row.device_face_model_name.indexOf('VM-F5') != -1)">
                </lbButton>
                <lbButton type="info" icon="sousuo" hint="预览" @click="handlePreview(scope.row)"
                  v-if="isShowBtn('63199658cc7f0000ae0058d0') && scope.row.MainId"></lbButton>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize"
            layout="total, sizes, prev, pager, next, jumper" :total="total">
          </el-pagination>
        </div>
      </le-card>

    </div>
    <!-- 新增编辑 对话框 -->
    <lebo-dialog append-to-body :title="addForm._id ? '编辑设备' : '新增设备'" :isShow="isAddDialog" width="30%"
      @close="closeAddDialogVisible()" footerSlot>
      <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="100px" class="demo-ruleForm">
        <el-form-item label="设备名称：" prop="device_face_name">
          <el-input v-model="addForm.device_face_name" placeholder="请输入设备名称" maxlength="30"
            @input="(e) => (addForm.device_face_name = validSpace(e))"></el-input>
        </el-form-item>
        <el-form-item label="设备品牌：" prop="device_face_brand_name">
          <el-select popper-class="my-select" v-model="addForm.device_face_brand_name" placeholder="请选择设备品牌"
            @change="brandChange">
            <el-option v-for="(item, index) in brandList" :key="index" :label="item.brand_name"
              :value="item.brand_name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设备型号：" prop="device_face_model_name">
          <el-select popper-class="my-select" v-model="addForm.device_face_model_name" placeholder="请选择设备型号"
            @change="modelChange">
            <el-option v-for="(item, index) in modelList" :key="index" :label="item.model_name"
              :value="item.model_name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="序列号：" prop="device_face_serial">
          <el-input v-model="addForm.device_face_serial" maxlength="30" placeholder="请输入序列号"
            @input="(e) => (addForm.device_face_serial = validFacility(e))"
            :disabled="addForm._id ? true : false"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <!-- <lbButton type="goBack" icon="back" @click="isAddDialog = false">返 回</lbButton> -->
        <lbButton type="default" fill icon="confirm" @click="submitAddForm()" v-preventReClick
          style="margin-right: unset;">保 存</lbButton>
      </span>
    </lebo-dialog>
    <!-- 预览 -->
    <FacePreview v-if="isFacePreview" :MainId="MainId" @close="isFacePreview = false" />
    <!-- 查看人脸识别一体机 -->
    <lebo-dialog append-to-body title="查看人脸识别一体机" :isShow="showLookFaceRecognition" width="50%"
      @close="showLookFaceRecognition = false" footerSlot closeOnClickModal>
      <lookFaceRecognition :id="dialogFaceRecognitionId" v-if="showLookFaceRecognition"></lookFaceRecognition>
    </lebo-dialog>
    <!-- 配置人脸识别一体机 -->
    <lebo-dialog class="editFaceRecognition" append-to-body title="配置人脸识别一体机" :isShow="showEditFaceRecognition"
      width="70%" @close="showEditFaceRecognition = false" footerSlot>
      <editFaceRecognition @closeDialog="closeFaceRecognition" :id="dialogFaceRecognitionId"
        v-if="showEditFaceRecognition"></editFaceRecognition>
    </lebo-dialog>
  </div>
</template>
<script>
import { getBrandList, getModelList } from '@/api/deviceApi'
import { getFaceDevicePageList, deleteFaceDevices, addFaceDevice, editFaceDevice } from '@/api/securityApi'
import { mapState, mapGetters, mapMutations } from 'vuex'
import FacePreview from './components/facePreview/facePreview.vue'
import lookFaceRecognition from './components/lookFaceRecognition/index.vue'
import editFaceRecognition from './components/editFaceDevice/index.vue'
export default {
  components: {
    FacePreview,
    lookFaceRecognition,
    editFaceRecognition
  },
  data () {
    return {
      // 查询表单
      queryForm: {
        PageSize: 10,
        PageIndex: 1,
        device_face_name: '',
        device_face_serial: '',
        device_state: null
      },
      total: 0,
      // 表格数据
      tableData: [],
      // 选中的表格数据
      multipleSelection: [],
      // 是否展示新增或编辑对话框
      isAddDialog: false,
      // 新增表单
      addForm: {
        _id: '',
        device_face_name: '', // 设备名称
        device_face_brand_id: '', // 设备品牌id
        device_face_brand_name: '', // 设备品牌名称
        device_face_model_id: '', // 设备型号id
        device_face_model_name: '', // 设备型号名称
        device_face_model_pic: '', // 设备型号图片
        device_face_serial: '' // 设备序列号
      },
      // 新增表单验证
      addFormRules: {
        device_face_name: [
          { required: true, message: '请输入设备名称', trigger: 'blur' }
        ],
        device_face_brand_name: [
          { required: true, message: '请选择设备品牌', trigger: 'blur' }
        ],
        device_face_model_name: [
          { required: true, message: '请选择设备型号', trigger: 'blur' }
        ],
        device_face_serial: [
          { required: true, message: '请输入序列号', trigger: 'blur' }
        ]
      },
      // 设备品牌下拉列
      brandList: [],
      // 设备型号下拉列
      modelList: [],
      isFacePreview: false, // 是否展示预览
      MainId: '',
      showLookFaceRecognition: false,
      showEditFaceRecognition: false,
      dialogFaceRecognitionId: '',
      // 表格插件配置项
      option: {
        searchMenuSpan: 8,
        header: false,
        delBtn: false, // 行删除按钮
        editBtn: false, // 编辑按钮
        selection: true,
        selectionFixed: false, // 解除选择框冻结
        // searchBtn:false,
        menuAlign: 'left', // 属性对齐方式
        menuHeaderAlign: 'left', // 表头对齐方式
        menu: true,
        menuFixed: false, // 解除操作框冻结
        tip: false,
        column: [
          {
            label: '序列号',
            prop: 'device_face_serial'
          }, {
            label: '设备名称',
            prop: 'device_face_name'
          }, {
            label: '设备品牌',
            prop: 'device_face_brand_name'
          }, {
            label: '设备型号',
            prop: 'device_face_model_name'
          }, {
            label: '状态',
            prop: 'device_state',
            slot: true
          }, {
            label: '创建时间',
            prop: 'create_time',
            slot: true
          }
        ]
      },
      // 分页器
      page: {
        background: false,
        total: 0,
        pageSize: 10,
        currentPage: 1
      }
    }
  },
  created () {
    if (this.queryStatus === 2) {
      this.queryForm = this.deepClone(this.queryObj)
    }
    this.fnGetFaceDevicePageList()
  },
  beforeRouteLeave (to, from, next) {
    this.setQueryObj(this.queryForm)
    this.setQueryStatus(1)
    next()
  },
  mounted () {
    window.addEventListener('beforeunload', e => this.beforeunloadFn(e))
  },
  computed: {
    ...mapState(['queryObj', 'queryStatus']),
    ...mapGetters(['isAdmin', 'getOptions', 'getWayName'])
  },
  destroyed () {
    window.removeEventListener('beforeunload', e => this.beforeunloadFn(e))
  },
  watch: {},
  methods: {
    ...mapMutations(['setQueryObj', 'setQueryStatus']),
    beforeunloadFn (e) {
      this.setQueryStatus(1)
    },
    async fnGetFaceDevicePageList () {
      const res = await getFaceDevicePageList({
        PageSize: this.queryForm.PageSize,
        PageIndex: this.queryForm.PageIndex,
        device_face_name: this.queryForm.device_face_name,
        device_face_serial: this.queryForm.device_face_serial,
        device_state: this.queryForm.device_state ? this.queryForm.device_state : null
      })
      this.tableData = res && res.Code === 200 && res.Data ? res.Data.DataList : []
      this.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
      this.page.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
    },
    // 查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      this.fnGetFaceDevicePageList()
    },
    // 每页条数改变
    handleSizeChange (val) {
      this.queryForm.PageSize = val
      this.fnGetFaceDevicePageList()
    },
    // // 每页条数改变
    // sizeChange (val) {
    //   this.page.currentPage = 1
    //   this.page.pageSize = val
    //   this.queryForm.PageSize = val
    //   this.fnGetFaceDevicePageList()
    // },
    // 页码改变
    handleCurrentChange (val) {
      this.queryForm.PageIndex = val
      this.fnGetFaceDevicePageList()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0
    },
    // // 页码改变
    // currentChange (val) {
    //   this.queryForm.PageIndex = val
    //   this.page.currentPage = val
    //   this.fnGetFaceDevicePageList()
    // },
    // // 选中的数据
    // selectionChange (list) {
    //   this.multipleSelection = list
    // },
    // 当选择项发生变化时会触发该事件
    handleSelectionChange (val) {
      // console.log(val);
      this.multipleSelection = val
    },
    // 批量删除
    batchDel () {
      if (this.multipleSelection.length > 0) {
        this.$confirm('此操作将永久删除选中的人脸识别一体机, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          var obj = {}
          var arr = []
          for (var i = 0; i < this.multipleSelection.length; i++) {
            arr.push(this.multipleSelection[i]._id)
            if (arr.length === this.multipleSelection.length) {
              obj._ids = arr
              console.log(obj)
              this.fnDeleteFaceDevices(obj)
            }
          }
        }).catch(() => {
          this.$msg.info('已取消删除')
        })
      } else {
        this.$msg.warning('请选择要删除的人脸识别一体机！')
      }
    },
    async fnDeleteFaceDevices (ids) {
      const res = await deleteFaceDevices(ids)
      if (res && res.Code === 200) {
        this.$msg.success(res.Message)
      }
      this.fnGetFaceDevicePageList()
    },
    // 打开新增设备对话框
    openAddForm () {
      this.addForm._id = ''
      this.addForm.device_face_brand_id = ''
      this.addForm.device_face_brand_name = ''
      this.addForm.device_face_model_id = ''
      this.addForm.device_face_model_name = ''
      this.addForm.device_face_model_pic = ''
      this.addForm.device_face_name = ''
      this.addForm.device_face_serial = ''
      this.isAddDialog = true
      this.fnGetBrandList()
    },
    // 打开修改设备对话框
    openEditForm (row) {
      console.log(row)
      this.isAddDialog = true
      this.fnGetBrandList()
      this.fnGetModelList(row.device_face_brand_id)
      var that = this
      this.$nextTick(() => {
        that.addForm._id = row._id
        that.addForm.device_face_brand_id = row.device_face_brand_id
        that.addForm.device_face_brand_name = row.device_face_brand_name
        that.addForm.device_face_model_id = row.device_face_model_id
        that.addForm.device_face_model_name = row.device_face_model_name
        that.addForm.device_face_model_pic = row.device_face_model_pic
        that.addForm.device_face_name = row.device_face_name
        that.addForm.device_face_serial = row.device_face_serial
      })
    },
    // 设备品牌下拉框
    async fnGetBrandList () {
      const res = await getBrandList({
        //  1:视频桩 2:地磁 3:高位相机 4:路牙机 5:车牌识别相机 6:显示屏 7:一体机
        device_type: 9
      })
      // console.log(res);
      this.brandList = res && res.Code === 200 ? res.Data : []
    },
    // 当设备品牌下拉框发生改变时
    brandChange (val) {
      // console.log(val);
      var index = this.brandList.findIndex(item => item.brand_name === val)
      this.addForm.device_face_brand_id = this.brandList[index]._id
      this.fnGetModelList(this.brandList[index]._id)
      this.addForm.device_face_model_id = ''
      this.addForm.device_face_model_name = ''
      this.addForm.device_face_model_pic = ''
    },
    // 设备型号下拉框
    async fnGetModelList (id) {
      const res = await getModelList({
        brand_id: id,
        //  1:视频桩 2:地磁 3:高位相机 4:路牙机 5:车牌识别相机 6:显示屏 7:一体机
        device_type: 9
      })
      // console.log(res);
      this.modelList = res && res.Code === 200 ? res.Data : []
    },
    // 当设备型号下拉框发生改变时
    modelChange (val) {
      // console.log(val);
      var index = this.modelList.findIndex(item => item.model_name === val)
      this.addForm.device_face_model_id = this.modelList[index]._id
      this.addForm.device_face_model_pic = this.modelList[index].pic
    },
    // 关闭新增编辑对话框，重置数据
    closeAddDialogVisible () {
      this.isAddDialog = false
      // 移除校验结果
      this.$refs.addFormRef.resetFields()
      this.addForm.device_face_brand_id = ''
      this.addForm.device_face_model_id = ''
      this.addForm.device_face_model_pic = ''
      this.addForm._id = ''
      console.log(this.addForm)
    },
    // 新增/编辑
    submitAddForm () {
      // 对整个表单进行校验
      this.$refs.addFormRef.validate((valid) => {
        // 成功
        if (valid) {
          var obj = {
            device_face_brand_id: this.addForm.device_face_brand_id,
            device_face_brand_name: this.addForm.device_face_brand_name,
            device_face_model_id: this.addForm.device_face_model_id,
            device_face_model_name: this.addForm.device_face_model_name,
            device_face_model_pic: this.addForm.device_face_model_pic,
            device_face_name: this.addForm.device_face_name,
            device_face_serial: this.addForm.device_face_serial
          }
          if (this.addForm._id) {
            obj._id = this.addForm._id
            // console.log(obj);
            this.fnEditFaceDevice(obj)
          } else {
            this.fnAddFaceDevice(obj)
          }
        } else {
          return false
        }
      })
    },
    // 新增人脸识别一体机设备请求
    async fnAddFaceDevice (obj) {
      console.log('fnAddFaceDevice----', obj)
      // this.isAddDialog = false
      const res = await addFaceDevice(obj)
      // console.log(res);
      if (res && res.Code === 200) {
        this.isAddDialog = false
        this.onSubmit()
      }
    },
    // 编辑人脸识别一体机设备请求
    async fnEditFaceDevice (obj) {
      const res = await editFaceDevice(obj)
      // console.log(res);
      if (res && res.Code === 200) {
        this.isAddDialog = false
        this.fnGetFaceDevicePageList()
      }
    },
    // 预览设备
    handlePreview (row) {
      console.log(row)
      this.MainId = row.MainId
      this.isFacePreview = true
    },
    lookFaceRecognition (id) {
      this.showLookFaceRecognition = true
      this.dialogFaceRecognitionId = id
    },
    editFaceRecognition (id) {
      this.showEditFaceRecognition = true
      this.dialogFaceRecognitionId = id
    },
    closeFaceRecognition () {
      this.showEditFaceRecognition = false
      this.fnGetFaceDevicePageList()
    }
  }
}
</script>
<style scoped lang="less">
.box-card {
  margin-top: 20px;
}

.el-select {
  width: 100%;
}

.editFaceRecognition {
  min-height: 50%;

  /deep/.el-dialog {
    min-height: 50%;

  }
}
</style>
