<template>
  <!-- 预览人脸识别机 -->
  <el-dialog :close-on-click-modal="false" class="dialog-video-preview" :visible.sync="isFacePreview" width="21%" @close="closeHandle" v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
    <video id="myVideo" v-if="sources" ref="videoPlayers" autoplay muted @playing="playing"></video>
    <div class="replacediv" v-else>
      <span> 无监控源 </span>
    </div>
  </el-dialog>
</template>
<script>
import flv from 'flv.js'
import { getFaceDeviceNVRMonitor } from '@/api/securityApi'
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
export default {
  props: ['MainId'],
  data () {
    return {
      isFacePreview: true,
      loading: true,
      //   url: "http://vjs.zencdn.net/v/oceans.mp4",
      url: '',
      player: null,
      isOver: false,
      timer: null,
      sources: '',
      recordeFlag: false,
      MediaServerId: '',
      ChannelName: ''
    }
  },
  created () { },
  mounted () {
    this.fnGetFaceDeviceNVRMonitor()
  },
  computed: {},
  watch: {},
  methods: {
    async fnGetFaceDeviceNVRMonitor () {
      const res = await getFaceDeviceNVRMonitor({ MainId: this.MainId })
      console.log(res)
      if (res && res.Code === 200) {
        if (res.Data.length === 0 || res.Data[0].PlayUrl.length === 0) {
          this.loading = false
          this.sources = ''
          return
        }
        this.sources = res.Data[0].PlayUrl[1]
        this.MediaServerId = res.Data[0].MediaServerId
        this.ChannelName = res.Data[0].ChannelName
        console.log('this.getMonitorUrlList======', JSON.stringify(res))

        setTimeout(() => {
          this.newplayer(res.Data[0].PlayUrl[1])
        }, 300)
      } else {
        this.loading = false
      }
    },
    newplayer (url) {
      if (flv.isSupported()) {
        var videoElement = document.getElementById('myVideo')
        console.log('=-==-=-=videoElement=', videoElement)
        this.player = flv.createPlayer(
          {
            type: 'flv',
            // isLive: true,
            // url: "http://188.128.0.25:6092/live?rtmpport=1935&app=live&stream=10",
            url: url,
            isLive: true,
            cors: true
          },
          {
            enableStashBuffer: false,
            stashInitialSize: 128,
            autoCleanupSourceBuffer: true
          }
        )
        this.player.attachMediaElement(videoElement)
        this.player.load() // 加载
      }
    },
    flv_start () {
      if (!this.player) return
      this.player
        .play()
        .then(() => {
          // 音频加载成功
          // 音频的播放需要耗时
          setTimeout(() => {
            // 后续操作
          }, 1000) // audio.duration 为音频的时长单位为秒
        })
        .catch((e) => {
          // 音频加载失败
        })
    },
    flv_pause () {
      if (!this.player) return
      this.player.pause()
    },
    flv_destroy () {
      if (!this.player) return
      this.resetPlayer()
    },
    resetPlayer () {
      this.player.pause()
      this.player.unload()
      this.player.detachMediaElement()
      this.player.destroy()
      this.player = null
    },
    playing () {
      if (this.loading) {
        this.loading = false
      }
    },
    closeHandle () {
      this.$emit('close')
    }
  },
  destroyed () {
    // console.log("==-===-=destroyed=======realeid====" + this.realeid);
    this.resetPlayer()
  }
}
</script>
<style scoped lang="less">
.dialog-video-preview {
  /deep/.el-dialog {
    width: fit-content !important;
    // background-color: rgba(0, 0, 0, 0) !important;
    .el-dialog__header {
      height: 0 !important;
      padding: 0 !important;
      .el-dialog__headerbtn {
        right: 5px !important;
        top: 5px !important;
        width: 30px !important;
        height: 30px !important;
        background-color: rgba(0, 0, 0, 0.8) !important;
        border-radius: 15px !important;
        overflow: hidden !important;
        z-index: 9999 !important;
      }
    }
    .el-dialog__body {
      object-fit: contain;
      padding: 0 !important;
      background-color: #fff;
      #myVideo {
        height: 600px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.9);
        object-fit: contain;
      }
      .replacediv {
        width: 500px;
        height: 600px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.9);
      }
    }
  }
}
</style>
