<template>
    <!-- 查看人脸识别机 -->
    <div class="box-card" style="text-align: left;margin-top:0;">
      <!-- 主要内容 -->
      <div class="content">
        <el-form label-position="right" label-width="120px" :model="detailsList" class="left">
          <!-- <div class="title">基本信息</div>
          <el-divider></el-divider> -->
          <el-form-item label="设备名称：">{{detailsList.device_face_name}}</el-form-item>
          <el-form-item label="设备品牌：">{{detailsList.device_face_brand_name}}</el-form-item>
          <el-form-item label="设备型号：">{{detailsList.device_face_model_name}}</el-form-item>
          <!-- <div class="title" style="margin-top:100px;">硬件属性</div>
          <el-divider></el-divider> -->
          <el-form-item label="序列号：">{{detailsList.device_face_serial}}</el-form-item>
          <!-- <el-form-item label="硬件版本：">{{detailsList.hardware_version}}</el-form-item> -->
          <el-form-item label="软件版本：">{{detailsList.software_version}}</el-form-item>
          <el-form-item label="IP地址：">{{detailsList.ip_address}}</el-form-item>
          <!-- <el-form-item label="默认网关：">{{detailsList.gateway_default}}</el-form-item>
          <el-form-item label="子网掩码：">{{detailsList.subnet_mask}}</el-form-item>
          <el-form-item label="首选DNS：">{{detailsList.first_choice_dns}}</el-form-item>
          <el-form-item label="备用DNS：">{{detailsList.spare_dns}}</el-form-item> -->
          <el-form-item label="设备状态：">
            <div :style="detailsList.device_state == 2 ? 'color: #ff0000;' : 'color: #18943b;'">{{detailsList.device_state == 2 ? "离线" : "在线" }}</div>
          </el-form-item>
          <el-form-item label="状态更新时间：">
            {{detailsList.heartbeat_time != "0001-01-01T00:00:00+08:05" ? $moment(detailsList.heartbeat_time).format("YYYY-MM-DD HH:mm:ss") : '' }}
          </el-form-item>
        </el-form>
        <div class="right">
          <el-image fit="contain" :src="detailsList.device_face_model_pic" :preview-src-list="[detailsList.device_face_model_pic]" v-if="detailsList.device_face_model_pic" :z-index="9999">
          </el-image>
          <div class="innerText">设备图片</div>
        </div>
      </div>
    </div>
  </template>

<script>
import { getFaceDeviceDetail } from '@/api/securityApi'
import { mapMutations } from 'vuex'
export default {
  props: ['id'],
  data () {
    return {
      detailsList: {}
    }
  },
  created () {
    this.fnGetFaceDeviceDetail()
  },
  mounted () { },
  computed: {
  },
  methods: {
    ...mapMutations(['setQueryStatus']),
    async fnGetFaceDeviceDetail () {
      const res = await getFaceDeviceDetail({
        face_id: this.id
      })
      this.detailsList = res.Data
    }

  }
}
</script>

  <style scoped lang="less">
  .content {
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: start;
  }
  .title {
    width: 100%;
    text-align: left;
    font-size: 18px;
    font-weight: 700;
    color: #000;
  }
  .content .left {
    width: 70%;
  }
  .left .el-form-item__content {
    text-align: left;
  }
  .left .el-row {
    width: 100%;
  }
  .left .el-form-item {
    margin-bottom: 0px;
  }
  .content .right {
    // max-width: 200px;
    // max-height: 200px;
    width: 40%;
    text-align: center;
    background-color: #FAFAFA;
    .innerText{
      width: 100%;
      text-align: center;
      color: #fff;
      background-color: #7D7D7D;
    }
    .el-image {
      width: 200px;
      height: 400px;
      .el-image__inner {
        width: 200px;
        height: 400px;
        text-align: right;
      }
    }
  }
  </style>
