<template>
  <!-- 配置人脸识别一体机 -->
  <div class="box-card" style="text-align: left; margin-top:0;">
    <el-form label-position="right" :rules="configFormRules" ref="configFormRefs" :model="configForm" class="config_box"
      size="medium">
      <el-tabs v-model="activeName">
        <el-tab-pane label="开门条件" name="开门条件">
          <div class="flex-box">
            <div class="left">
              <el-form-item label="开门方式">
                <el-select popper-class="my-select" v-model="configForm.OpenDoorConfig.OpendoorWay" placeholder="请选择开门方式"
                  @change="OpendoorWaySelectChange">
                  <el-option label="人脸开门方式" :value="0"></el-option>
                  <el-option label="远程开门方式" :value="1"></el-option>
                  <el-option label="远程开门或人脸方式" :value="2"></el-option>
                  <el-option label="人脸核验，远程开门方式" :value="3"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="黑白名单验证门限">
                <el-select popper-class="my-select" v-model="configForm.OpenDoorConfig.ContrastValue" placeholder="请选择参数">
                  <el-option v-for="item in contrastValueSelectList" :label="item" :value="item" :key="item"></el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="验证复位时间(秒)">
                <el-select popper-class="my-select" v-model="configForm.OpenDoorConfig.VerifyResetTime"
                  placeholder="请选择参数">
                  <el-option v-for="item in verifyResetTimeSelectList" :label="item" :value="item"
                    :key="item"></el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="未佩戴口罩允许通行"
                v-if="device_face_model_name && (device_face_model_name.indexOf('VM-F5') != -1)">
                <el-checkbox v-model="configForm.OpenDoorConfig.IsMaskOK"></el-checkbox>
              </el-form-item>
            </div>
            <div class="right">
              <el-form-item label="开门验证方式">
                <el-select popper-class="my-select" v-model="configForm.OpenDoorConfig.OpenCondition"
                  placeholder="请选择开门方式">
                  <el-option v-for="item in openTypeList" :key="item.value" :label="item.name"
                    :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="身份证验证门限"
                v-if="device_face_model_name && (device_face_model_name.indexOf('VM-F5') != -1)">
                <el-select popper-class="my-select" v-model="configForm.OpenDoorConfig.IDCardThreshold"
                  placeholder="请选择参数">
                  <el-option v-for="item in IDCardThresholdSelectList" :label="item" :value="item"
                    :key="item"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="识别间隔时间(秒)">
                <el-select popper-class="my-select" v-model="configForm.OpenDoorConfig.SnapResetTime" placeholder="请选择参数">
                  <el-option v-for="item in snapResetTimeSelectList" :label="item" :value="item" :key="item"></el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>
          <div class="title">闸机控制接口</div>
          <el-divider></el-divider>
          <div class="flex-box">
            <div class="left">
              <el-form-item label="控制接口">
                <el-select popper-class="my-select" v-model="configForm.OpenDoorConfig.Controltype" placeholder="请选择参数">
                  <el-option label="韦根接口" :value="0"></el-option>
                  <el-option label="开关量" :value="1"></el-option>
                  <el-option label="韦根接口 + 开关量" :value="2"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="比对不通过输出韦根FF">
                <el-select popper-class="my-select" v-model="configForm.OpenDoorConfig.IsOutFF" placeholder="请选择参数">
                  <el-option label="不启用" :value="0"></el-option>
                  <el-option label="启用" :value="1"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="韦根协议" v-if="configForm.OpenDoorConfig.Controltype == 2">
                <el-select popper-class="my-select" v-model="configForm.OpenDoorConfig.Wiegand" placeholder="请选择参数">
                  <el-option label="26位" :value="0"></el-option>
                  <el-option label="34位" :value="1"></el-option>
                  <el-option label="26位（8+16）" :value="4"></el-option>
                  <el-option label="34位（8+24）" :value="5"></el-option>
                  <el-option label="26位（8+16 分开）" :value="6"></el-option>
                  <el-option label="34位（8+24 分开）" :value="7"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="公用门禁卡号" v-if="configForm.OpenDoorConfig.Controltype == 2">
                <el-input maxlength="9" placeholder="请输入公用门禁卡号" v-model="configForm.OpenDoorConfig.PublicMjCardNo"
                  @input="(e) => (configForm.OpenDoorConfig.PublicMjCardNo = validNumberInteger(e))"></el-input>
              </el-form-item>
            </div>
            <div class="right">
              <el-form-item label="自动生成门禁卡号时的范围" v-if="configForm.OpenDoorConfig.Controltype == 2">
                <el-input maxlength="9" placeholder="请输入开始卡号" v-model="configForm.OpenDoorConfig.AutoMjCardBgnNo"
                  @input="(e) => (configForm.OpenDoorConfig.AutoMjCardBgnNo = validNumberInteger(e))"></el-input>
                <span style="margin:0 10px;">~</span>
                <el-input maxlength="9" placeholder="请输入结束卡号" v-model="configForm.OpenDoorConfig.AutoMjCardEndNo"
                  @input="(e) => (configForm.OpenDoorConfig.AutoMjCardEndNo = validNumberInteger(e))"></el-input>
              </el-form-item>
              <el-form-item label="保持时间（毫秒）" v-if="configForm.OpenDoorConfig.Controltype != 0">
                <el-input maxlength="9" placeholder="请输入保持时间" v-model="configForm.OpenDoorConfig.Keeptime"
                  @input="(e) => (configForm.OpenDoorConfig.Keeptime = validNumberInteger(e))"></el-input>
              </el-form-item>

              <el-form-item label="开门接口类型">
                <el-select popper-class="my-select" v-model="configForm.OpenDoorConfig.Controblyope" placeholder="请选择参数">
                  <el-option label="常关" :value="0"></el-option>
                  <el-option label="常开" :value="1"></el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>

        </el-tab-pane>
        <el-tab-pane label="提示音及界面" name="提示音及界面">
          <div class="flex-box">
            <div class="left">
              <el-form-item label="音量">
                <el-select popper-class="my-select" v-model="configForm.ConfigShow.Volume" placeholder="请选择音量大小">
                  <el-option v-for="item in thresholdSelectList" :label="item" :value="item" :key="item"></el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="比对成功显示">
                <el-select popper-class="my-select" v-model="configForm.ConfigShow.Check_true_Show" placeholder="请选择参数">
                  <el-option label="抓拍头像" :value="1"></el-option>
                  <el-option label="注册头像" :value="2"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="公司名称显示">
                <el-input placeholder="请输入公司名称" maxlength="30" v-model="configForm.ConfigShow.CompanyName"
                  @input="(e) => (configForm.ConfigShow.CompanyName = validSpace(e))"></el-input>
              </el-form-item>
              <el-form-item label="验证成功提示语">
                <el-input placeholder="请输入验证成功提示语" maxlength="30" v-model="configForm.ConfigShow.Check_true_msg"
                  @input="(e) => (configForm.ConfigShow.Check_true_msg = validSpace(e))"></el-input>
              </el-form-item>
              <el-form-item label="名单未注册提示语">
                <el-input placeholder="请输入名单未注册提示语" maxlength="30" v-model="configForm.ConfigShow.Check_Reg_msg"
                  @input="(e) => (configForm.ConfigShow.Check_Reg_msg = validSpace(e))"></el-input>
              </el-form-item>
              <el-form-item label="验证黑名单提示语">
                <el-input placeholder="请输入验证黑名单提示语" maxlength="30" v-model="configForm.ConfigShow.Check_black_msg"
                  @input="(e) => (configForm.ConfigShow.Check_black_msg = validSpace(e))"></el-input>
              </el-form-item>
              <!-- <el-form-item label="待机界面">
                  <div style="display: flex; align-items: center;">
                    <el-image style="width: 40px; height:40px;margin-right:10px;" :src="configForm.ConfigShow.StandbyPic" fit="cover" :preview-src-list="[configForm.ConfigShow.StandbyPic]" v-if="configForm.ConfigShow.StandbyPic"></el-image>
                    <el-upload :action="''" :show-file-list="false" :http-request="fnUploadStandbyPic" :multiple="false" accept="image/*">
                      <el-button size="small" type="info">{{configForm.ConfigShow.StandbyPic ? '重新上传' : '上传图片'}}</el-button>
                    </el-upload>
                  </div>
                </el-form-item>
                <el-form-item label="开机启动页">
                  <div style="display: flex; align-items: center;">
                    <el-image style="width: 40px; height:40px;margin-right:10px;" :src="configForm.ConfigShow.StartingupPic" fit="cover" :preview-src-list="[configForm.ConfigShow.StartingupPic]" v-if="configForm.ConfigShow.StartingupPic"></el-image>
                    <el-upload :action="''" :show-file-list="false" :http-request="fnUploadStartingupPic" :multiple="false" accept="image/*">
                      <el-button size="small" type="info">{{configForm.ConfigShow.StartingupPic ? '重新上传': '上传图片'}}
                      </el-button>
                    </el-upload>
                  </div>
                </el-form-item>
                <el-form-item label="允许通行">
                  <div class="audio_box">
                    <el-input v-model="PassableAudioName" disabled v-if="configForm.ConfigShow.PassableAudio"></el-input>
                    <el-upload :action="''" :show-file-list="false" :http-request="fnUploadPassableAudio" :multiple="false" accept="audio/*">
                      <el-button size="small" type="info">{{configForm.ConfigShow.PassableAudio?"重新上传":"更换音频"}}</el-button>
                    </el-upload>
                  </div>
                </el-form-item>
                <el-form-item label="禁止通行">
                  <div class="audio_box">
                    <el-input v-model="NoAccessAudioName" disabled v-if="configForm.ConfigShow.NoAccessAudio"></el-input>
                    <el-upload :action="''" :show-file-list="false" :http-request="fnUploadNoAccessAudio" :multiple="false" accept="audio/*">
                      <el-button size="small" type="info">{{configForm.ConfigShow.NoAccessAudio?"重新上传":"更换音频"}}</el-button>
                    </el-upload>
                  </div>
                </el-form-item>
                <el-form-item label="陌生人">
                  <div class="audio_box">
                    <el-input v-model="StrangerAudioName" disabled v-if="configForm.ConfigShow.StrangerAudio"></el-input>
                    <el-upload :action="''" :show-file-list="false" :http-request="fnUploadStrangerAudio" :multiple="false" accept="audio/*">
                      <el-button size="small" type="info">{{configForm.ConfigShow.StrangerAudio?"重新上传":"更换音频"}}</el-button>
                    </el-upload>
                  </div>
                </el-form-item> -->
            </div>
            <div class="right">
              <el-form-item label="是否显示名字">
                <el-select popper-class="my-select" v-model="configForm.ConfigShow.IsShowName" placeholder="请选择是否显示名字">
                  <el-option label="不显示" :value="false"></el-option>
                  <el-option label="显示" :value="true"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="验证成功播放声音">
                <el-checkbox v-model="configForm.ConfigShow.Check_true_voice"></el-checkbox>
              </el-form-item>
              <el-form-item label="验证黑名单播放声音">
                <el-checkbox v-model="configForm.ConfigShow.Check_black_voice"></el-checkbox>
              </el-form-item>

              <el-form-item label="远程控制播放声音">
                <el-checkbox v-model="configForm.ConfigShow.RemoteCtrlAudio"></el-checkbox>
              </el-form-item>
              <el-form-item label="验证成功界面提示">
                <el-checkbox v-model="configForm.ConfigShow.Check_true_Tips"></el-checkbox>
              </el-form-item>
              <el-form-item label="验证黑名单界面提示">
                <el-checkbox v-model="configForm.ConfigShow.Check_black_Tips"></el-checkbox>
              </el-form-item>
              <el-form-item label="名单未注册界面提示">
                <el-checkbox v-model="configForm.ConfigShow.Check_Reg_Tips"></el-checkbox>
              </el-form-item>
              <el-form-item label="IC卡号隐藏">
                <el-checkbox v-model="configForm.ConfigShow.is_Show_IC"></el-checkbox>
                <!-- <span style="color:red;">设置无效</span> -->
              </el-form-item>
              <el-form-item label="IP隐藏">
                <el-checkbox v-model="configForm.ConfigShow.IsShowIPaddr"></el-checkbox>
                <!-- <span style="color:red;">设置无效</span> -->
              </el-form-item>
              <el-form-item label="是否显示本机ID">
                <el-checkbox v-model="configForm.ConfigShow.IsShowDeviceID"></el-checkbox>
              </el-form-item>
              <el-form-item label="已注册名单数量提示">
                <el-checkbox v-model="configForm.ConfigShow.IsShowRegPerCount"></el-checkbox>
              </el-form-item>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="人脸参数设置" name="人脸参数设置">
          <div class="face_box">
            <el-form-item label="最大识别距离（厘米）">
              <el-select popper-class="my-select" v-model="configForm.IdentificationArea.FaceMinipiXel"
                placeholder="请选择最大识别距离">
                <el-option v-for="item in faceMinipiXelSelectList" :label="item" :value="item" :key="item"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="人脸检测区域X轴">
              <el-input placeholder="请输入人脸检测区域X轴" maxlength="4" type="number"
                v-model="configForm.IdentificationArea.LeftCornerX"
                @input="(e) => (configForm.IdentificationArea.LeftCornerX = Number(e))"></el-input>
              <span>（范围：0~960px）</span>
              <!-- <span style="color:red;">设置失败，设置的值对应不上</span> -->
            </el-form-item>
            <el-form-item label="人脸检测区域Y轴">
              <el-input placeholder="请输入人脸检测区域Y轴" maxlength="4" type="number"
                v-model="configForm.IdentificationArea.LeftCornerY"
                @input="(e) => (configForm.IdentificationArea.LeftCornerY = Number(e))"></el-input>
              <span>（范围：0~1280px）</span>
            </el-form-item>
            <el-form-item label="人脸检测区域宽度">
              <el-input placeholder="请输入人脸检测区域宽度" maxlength="4" type="number"
                v-model="configForm.IdentificationArea.DetectWidth"
                @input="(e) => (configForm.IdentificationArea.DetectWidth = Number(e))"></el-input>
              <span>（范围：0~960px）</span>
              <!-- <span style="color:red;">设置失败，设置的值对应不上</span> -->
            </el-form-item>
            <el-form-item label="人脸检测区域高度">
              <el-input placeholder="请输入人脸检测区域高度" maxlength="4" type="number"
                v-model="configForm.IdentificationArea.DetectHeight"
                @input="(e) => (configForm.IdentificationArea.DetectHeight = Number(e))"></el-input>
              <span>（范围：0~1280px）</span>
            </el-form-item>
          </div>
        </el-tab-pane>
        <el-tab-pane label="系统参数" name="系统参数">
          <div class="face_box">
            <el-form-item label="语言">
              <el-select popper-class="my-select" v-model="configForm.systemparameter.Language" placeholder="请选择参数">
                <el-option label="英文" :value="0"></el-option>
                <el-option label="中文简体" :value="1"></el-option>
                <el-option label="中文繁体" :value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="身份证读卡器类型"
              v-if="device_face_model_name && (device_face_model_name.indexOf('VM-F5') != -1)">
              <el-select popper-class="my-select" v-model="configForm.systemparameter.IDcardtype" placeholder="请选择参数">
                <el-option label="中控（IDM30），腾研，华视（CVR-100B），博雅" :value="0"></el-option>
                <el-option label="精伦（IDR210）" :value="1"></el-option>
                <el-option label="中控（IDM30，IDM10）" :value="2"></el-option>
                <el-option label="华视（CVR-100UC）" :value="3"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="是否显示人脸框">
              <el-checkbox v-model="configForm.systemparameter.FaceDisplay"></el-checkbox>
            </el-form-item>
            <el-form-item label="记录抓拍+记录身份证+记录认证">
              <el-checkbox v-model="configForm.systemparameter.DataBaseEnable"></el-checkbox>
            </el-form-item>
            <el-form-item label="活体检测模式">
              <el-select popper-class="my-select" v-model="configForm.systemparameter.LiveDetecttype" placeholder="请选择参数">
                <el-option label="一直开启" :value="0"></el-option>
                <el-option label="关闭" :value="1"></el-option>
                <el-option label="按时间段开启" :value="2"></el-option>
              </el-select>
              <template v-if="configForm.systemparameter.LiveDetecttype == 2">
                <el-time-picker placeholder="起始时间" v-model="configForm.systemparameter.LiveDetectTimeBeg"
                  :default-value="$moment().format('YYYY-MM-DD ') + '00:00:00'" value-format="HH:mm:ss" :picker-options="{
                    selectableRange: '00:00:00 - 23:59:59',
                    maxTime: configForm.systemparameter.LiveDetectTimeEnd
                  }">
                </el-time-picker>
                <span style="margin-right: 10px;">~</span>
                <el-time-picker placeholder="结束时间" v-model="configForm.systemparameter.LiveDetectTimeEnd"
                  :default-value="$moment().format('YYYY-MM-DD ') + '23:59:59'" value-format="HH:mm:ss" :picker-options="{
                    selectableRange: '00:00:00 - 23:59:59',
                    minTime: configForm.systemparameter.LiveDetectTimeBeg
                  }">
                </el-time-picker>
              </template>

            </el-form-item>
            <el-form-item label="启用白光灯类型">
              <el-select popper-class="my-select" v-model="configForm.systemparameter.LedLighttype" placeholder="请选择参数">
                <el-option label="从不" :value="0"></el-option>
                <el-option label="时间控制" :value="1"></el-option>
                <el-option label="光敏感应" :value="2"></el-option>
                <el-option label="人脸感应" :value="3"></el-option>
                <el-option label="人脸感应 或 时间控制" :value="4"></el-option>
                <el-option label="人脸感应 或 光感感应" :value="5"></el-option>
              </el-select>
              <template
                v-if="configForm.systemparameter.LedLighttype == 1 || configForm.systemparameter.LedLighttype == 4">
                <span style="margin: 0 10px;white-space: nowrap;">关闭</span>
                <el-time-picker placeholder="结束时间" v-model="configForm.systemparameter.LedTimeEnd"
                  :default-value="$moment().format('YYYY-MM-DD ') + '23:00:00'" value-format="HH:mm:ss" :picker-options="{
                    selectableRange: '00:00:00 - 23:59:59',
                    minTime: configForm.systemparameter.LedTimeBeg
                  }">
                </el-time-picker>
                <span style="margin-right: 10px;white-space: nowrap;">打开</span>
                <el-time-picker placeholder="起始时间" v-model="configForm.systemparameter.LedTimeBeg"
                  :default-value="$moment().format('YYYY-MM-DD ') + '00:00:00'" value-format="HH:mm:ss" :picker-options="{
                    selectableRange: '00:00:00 - 23:59:59',
                    maxTime: configForm.systemparameter.LedTimeEnd
                  }">
                </el-time-picker>
              </template>
            </el-form-item>
            <template v-if="configForm.systemparameter.LedLighttype != 0">
              <el-form-item label="白光灯亮度">
                <el-input placeholder="请输入白光灯亮度" maxlength="4" type="number"
                  v-model="configForm.systemparameter.LedBrightness"
                  @input="(e) => (configForm.IdentificationArea.LedBrightness = Number(e))"></el-input>
                (1~100)
              </el-form-item>
            </template>
            <template
              v-if="configForm.systemparameter.LedLighttype == 3 || configForm.systemparameter.LedLighttype == 4 || configForm.systemparameter.LedLighttype == 5">
              <el-form-item label="无人多少秒后关闭白光灯">
                <el-input placeholder="请输入数字" maxlength="4" type="number"
                  v-model="configForm.systemparameter.LedDisableAfterSec"
                  @input="(e) => (configForm.IdentificationArea.LedDisableAfterSec = Number(e))"></el-input>
              </el-form-item>
            </template>
            <el-form-item label="屏幕显示模式">
              <el-select popper-class="my-select" v-model="configForm.systemparameter.LcdBLDisable" placeholder="请选择参数">
                <el-option label="一直显示" :value="false"></el-option>
                <el-option label="无人时关闭屏幕显示" :value="true"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="无人多少秒后关闭屏幕" v-if="configForm.systemparameter.LcdBLDisable">
              <el-input placeholder="请输入数字" maxlength="4" type="number"
                v-model="configForm.systemparameter.LcdBLDisableAfterSec"
                @input="(e) => (configForm.IdentificationArea.LcdBLDisableAfterSec = Number(e))"></el-input>
              （10 ~ 600）
            </el-form-item>
            <el-form-item label="web超时时间">
              <el-input placeholder="请输入数字"  maxlength="4" type="number" v-model="configForm.systemparameter.WebTimeOut"
                @input="(e) => (configForm.IdentificationArea.WebTimeOut = Number(e))"></el-input>
              1 ~ 10（min）
            </el-form-item>
          </div>

        </el-tab-pane>
        <el-tab-pane label="MQTT设置" name="MQTT设置">
          <div class="flex-box">
            <div class="left">
              <el-form-item label="闸机云ID号">
                <el-input v-model="configForm.mqttparameter.facesluiceId" maxlength="30"
                  @input="(e) => (configForm.mqttparameter.facesluiceId = validFacility(e))"
                  placeholder="请输入闸机云ID号"></el-input>
              </el-form-item>
              <el-form-item label="云地址">
                <el-input v-model="configForm.mqttparameter.MQAddr" maxlength="30"
                  @input="(e) => (configForm.mqttparameter.MQAddr = validFacility(e))" placeholder="请输入云地址"></el-input>
              </el-form-item>
              <el-form-item label="云端口">
                <el-input v-model="configForm.mqttparameter.MQPort" maxlength="30"
                  @input="(e) => (configForm.mqttparameter.MQPort = validFacility(e))" placeholder="请输入云端口"></el-input>
              </el-form-item>
              <el-form-item label="出入口类型">
                <el-select popper-class="my-select" v-model="configForm.mqttparameter.Direction" placeholder="请选择参数">
                  <el-option label="入口" :value="0"></el-option>
                  <el-option label="出口" :value="1"></el-option>
                  <el-option label="无方向" :value="2"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="云端用户名">
                <el-input v-model="configForm.mqttparameter.MQUser" maxlength="30"
                  @input="(e) => (configForm.mqttparameter.MQUser = validFacility(e))" placeholder="请输入云端用户名"></el-input>
              </el-form-item>
              <el-form-item label="云端密码">
                <el-input v-model="configForm.mqttparameter.MQPwd" type="password" maxlength="30" show-password
                  @input="(e) => (configForm.mqttparameter.MQPwd = validFacility(e))" placeholder="请输入云端密码"></el-input>
              </el-form-item>
              <el-form-item label="云话题Topic">
                <el-input v-model="configForm.mqttparameter.MQTopic" type="password" maxlength="30" show-password
                  @input="(e) => (configForm.mqttparameter.MQTopic = validFacility(e))"
                  placeholder="请输入云话题Topic"></el-input>
              </el-form-item>
            </div>
            <div class="right">
              <el-form-item label="陌生人">
                <el-select popper-class="my-select" v-model="configForm.mqttparameter.StrangerUploadType"
                  placeholder="请选择参数">
                  <el-option label="上传" :value="true"></el-option>
                  <el-option label="不上传" :value="false"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="识别记录">
                <el-select popper-class="my-select" v-model="configForm.mqttparameter.RecordUploadtype"
                  placeholder="请选择参数">
                  <el-option label="不上传" :value="0"></el-option>
                  <el-option label="上传带图片" :value="1"></el-option>
                  <el-option label="上传不带图片" :value="2"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="二维码">
                <el-select popper-class="my-select" v-model="configForm.mqttparameter.QRCode" placeholder="请选择参数">
                  <el-option label="上传" :value="true"></el-option>
                  <el-option label="不上传" :value="false"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="身份证">
                <el-select popper-class="my-select" v-model="configForm.mqttparameter.IDCard" placeholder="请选择参数">
                  <el-option label="上传" :value="true"></el-option>
                  <el-option label="不上传" :value="false"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="IC或RF卡号">
                <el-select popper-class="my-select" v-model="configForm.mqttparameter.Card" placeholder="请选择参数">
                  <el-option label="上传" :value="true"></el-option>
                  <el-option label="不上传" :value="false"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="断网续传">
                <el-select popper-class="my-select" v-model="configForm.mqttparameter.ResumefromBreakpoint"
                  placeholder="请选择参数">
                  <el-option label="启用" :value="true"></el-option>
                  <el-option label="不启用" :value="false"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="重传起始时间" v-if="configForm.mqttparameter.ResumefromBreakpoint">
                <el-date-picker v-model="configForm.mqttparameter.BeginTime" value-format="yyyy-MM-dd HH:mm:ss"
                  type="datetime" placeholder="选择日期时间">
                </el-date-picker>
              </el-form-item>
            </div>
          </div>
          <!-- <span style="color:red;font-size:18px;">MQTT设置无效</span> -->
        </el-tab-pane>
        <el-tab-pane label="RTSP设置" name="RTSP设置"
          v-if="device_face_model_name && (device_face_model_name.indexOf('VM-F5') != -1)">
          <div class="face_box">
            <el-form-item label="开启验证">
              <el-checkbox v-model="configForm.rtspparameter.OpenVerify"></el-checkbox>
            </el-form-item>
            <el-form-item label="打包大小">
              <el-input placeholder="请输入打包大小" maxlength="4" type="number" v-model="configForm.rtspparameter.PackSize"
                @input="(e) => (configForm.rtspparameter.PackSize = Number(e))"></el-input>
              <span>1~1500（默认:1500）</span>
            </el-form-item>
            <el-form-item label="RTSP 端口">
              <el-input placeholder="请输入 RTSP 端口" maxlength="4" type="number" v-model="configForm.rtspparameter.RTSPPort"
                @input="(e) => (configForm.rtspparameter.RTSPPort = Number(e))"></el-input>
            </el-form-item>
          </div>

        </el-tab-pane>
        <el-tab-pane label="广告服务" name="广告服务"
          v-if="device_face_model_name && (device_face_model_name.indexOf('VM-F5') != -1)">
          <div class="face_box">
            <el-form-item label="广告槽位">
              <el-select popper-class="my-select" v-model="configForm.advertisingparameters.adslot" placeholder="请选择参数">
                <el-option label="0" :value="0"></el-option>
                <el-option label="1" :value="1"></el-option>
                <el-option label="2" :value="2"></el-option>
                <el-option label="3" :value="3"></el-option>
                <el-option label="4" :value="4"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="播放时长">
              <el-input placeholder="请输入播放时长" maxlength="4" type="number"
                v-model="configForm.advertisingparameters.polltime"
                @input="(e) => (configForm.advertisingparameters.polltime = Number(e))"></el-input>
            </el-form-item>
            <el-form-item label="上传广告">
              <div style="display: flex; align-items: center;">
                <el-image style="width: 40px; height:40px;margin-right:10px;" :src="configForm.advertisingparameters.path"
                  fit="cover" :preview-src-list="[configForm.advertisingparameters.path]"
                  v-if="configForm.advertisingparameters.path"></el-image>
                <el-upload :action="''" :show-file-list="false" :http-request="fnUploadAdvertising" :multiple="false"
                  accept="image/*">
                  <el-button size="small" type="info">{{ configForm.advertisingparameters.path ? '重新上传' : '上传广告' }}
                  </el-button>
                </el-upload>
                <el-button size="small" type="warning" style="margin-left: 18px;"
                  @click="configForm.advertisingparameters.path = ''"
                  v-if="configForm.advertisingparameters.path">下架广告</el-button>
                <!-- <span style="color:red;font-size:18px;">不确定是否上传成功</span> -->
              </div>
            </el-form-item>
          </div>

        </el-tab-pane>
        <el-tab-pane label="实时视频" name="实时视频">
          <div class="face_box">
            <el-form-item label="RTSP地址：">
              <el-input v-model="configForm.rtspurl" placeholder="rtsp://设备IP/av0_0"
                @input="(e) => (configForm.rtspurl = validSpacing(e))"></el-input>
            </el-form-item>
            <el-form-item label="流媒体服务器：">
              <el-select popper-class="my-select" v-model="configForm.MediaServerId" placeholder="请选择流媒体服务器">
                <el-option v-for="(item, index) in mediaServerList" :key="index" :label="item" :value="item"></el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-tab-pane>
        <el-tab-pane label="高级配置" name="高级配置">
          <el-form-item label="恢复出厂">
            <lbButton size="small" type="warning" @click="isResetDialog = true">恢复出厂</lbButton>
          </el-form-item>
          <el-form-item label="重启设备">
            <lbButton size="small" type="succes" @click="fnResetFaceDevice">立即重启设备</lbButton>
          </el-form-item>
          <el-form-item label="远程开门">
            <lbButton size="small" @click="fnOpenFaceDevice">点击开门</lbButton>
          </el-form-item>
        </el-tab-pane>
      </el-tabs>
    </el-form>
    <FaceDeviceReset :device_face_serial="device_face_serial" v-if="isResetDialog"
      @close="isResetDialog = false; fnGetFaceDeviceDetail()"></FaceDeviceReset>
    <!-- <lbButton type="goBack" icon="back" class="checkButton3" @click="defaultForm">返 回</lbButton> -->
    <lbButton type="succes" class="checkButton2" icon="rerefresh" @click="fnRefreshDeviceConfig">刷 新</lbButton>
    <lbButton type="default" fill icon="confirm" class="checkButton" @click="saveConfig">保 存</lbButton>
  </div>
</template>
<script>
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
import { getFaceDeviceDetail, refreshDeviceConfig, getFaceDeviceOpenTypeByModelNamel, uploadImage, uploadFile, setDeviceConfig, openFaceDevice, resetFaceDevice, factoryResetFaceDevice } from '@/api/securityApi'
import { getMediaServerList } from '@/api/monitoring'
import FaceDeviceReset from '../faceDeviceConfig/faceDeviceReset.vue'
export default {
  props: ['id'],
  components: {
    FaceDeviceReset
  },
  data () {
    return {
      isResetDialog: false, // 重启对话框
      activeName: '开门条件',
      mediaServerList: [], // 流媒体服务器列表
      openTypeList: [], // 开门方式
      // 黑白名单验证门限列表
      contrastValueSelectList: [50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100],
      // 身份证验证门限
      IDCardThresholdSelectList: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100],
      // 验证复位时间(秒)
      verifyResetTimeSelectList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      // 音量列表
      thresholdSelectList: [100, 95, 90, 85, 80, 75, 70, 65, 60, 55, 50, 45, 40, 35, 30, 25, 20, 15, 10, 5],
      // 最大识别距离列表
      faceMinipiXelSelectList: [30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160, 170, 180, 190, 200],
      // 识别间隔时间
      snapResetTimeSelectList: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
      configForm: {
        ip_address: null, // ip地址
        DHCP_State: false, // DHCP状态：true:开启(1) , false:关闭(0)
        rtspurl: '', // rtsp地址
        MediaServerId: '', // 流媒体服务器ID
        OpenDoorConfig: {
          OpendoorWay: 1, // 开门方式 0:人脸开门方式，1:远程开门方式，2:远程开门或人脸方式 3:人脸核验 + 远程开门方式....
          ContrastValue: 10, // 黑/白名单比对门限
          IDCardThreshold: 10, // 身份证门限对比
          OpenCondition: 1, //  开门方式 1.白名单+人脸 2.身份证+人脸 3.白名单+身份证 4.白名单或身份证+人脸
          VerifyResetTime: 0, // 验证复位时间（秒） UI 显示提示信息以及同一人员验证复位时间内不再次识别的时间
          Wiegand: 0, // 韦根协议类型   二十六位 = 0,三十四位 = 1,二十六位_8_16 = 4, // facilitycode+userid(设备代码+卡号);三十四位_8_24 = 5,二十六位_8_16分开填写 = 6,//(8+16 分开填写)三十四位_8_24分开填写 = 7 //(8+24 分开填写)
          PublicMjCardNo: 0, // 公用门禁卡号
          AutoMjCardBgnNo: 0, // 自动生成门禁卡号的开始卡号
          AutoMjCardEndNo: 0, // 自动生成门禁卡号的结束卡号
          Controltype: 0, //  控制开门接口方式  韦根接口 = 0,开关量 = 1,韦根接口_开关量 = 2
          Controblyope: 1, // 闸机控制 常关 = 0,常开 = 1
          Keeptime: 180, // 保持时间
          IsOutFF: 0, // 陌生人韦根输出 8 个 F(韦根开门) 0: 不输出 1: 输出
          SnapResetTime: 0, // 识别间隔时间(s):比对通过后等待时间识别下一个人
          IsMaskOK: 0 // 未佩戴口罩允许通行，配合口罩检测使用，(特殊版本支持) 0: 不允许 1: 允许
        }, // 开门条件
        IdentificationArea: {
          device_face_serial: '', // 设备序列号
          LeftCornerX: 10, // 人脸检测区域X轴
          LeftCornerY: 20, // 人脸检测区域Y轴
          DetectWidth: 25, // 人脸检测区域宽度
          DetectHeight: 30, // 人脸检测区域高度
          FaceMinipiXel: 50 // 人脸最小像素
        }, // 人脸设备识别区域
        ConfigShow: {
          Check_true_voice: false, // 认证成功是否声音播报 false: 不播报 true: 播报
          Check_black_voice: true, // 认证失败是否声音播报 false: 不播报 ture: 播报
          Volume: 0, // 音量值
          Check_true_Tips: false, // 认证成功界面是否提示 false: 不提示 true: 提示
          Check_black_Tips: false, // 认证失败界面是否提示 false: 不提示 true: 提示
          Check_Reg_Tips: false, // 名单未注册界面是否提示 false: 不提示 true: 提示
          Check_true_Show: 0, // 比对成功界面提示图像类型 0: 抓拍图像 1: 注册图像
          RemoteCtrlAudio: 1, // 远程控制播放声音 0: 不播放声音 1: 播放声音
          IsShowIPaddr: false, // 界面 IP 是否隐藏 true: 不隐藏 false: 隐藏
          IsShowName: false, // 比对成功，名字提示是否显示 false: 不显示 true: 显示
          IsShowRegPerCount: true, // 显示注册名单数 false: 不显示 true: 显示
          is_Show_IC: false, // 界面 IC 卡号是否隐藏（除内置刷卡机型外） 0: true 1: false
          Check_true_msg: '', // 认证成功 UI 文字提示（长度 64 字节以内） 默认：请通行
          Check_Reg_msg: '', // 名单未注册 UI 文字提示（长度 64字节以内）默认：陌生人
          Check_black_msg: '', // 认证失败 UI 文字提示（长度 64 字节以内）默认：禁止通行
          IDCardNumHide: false, // 界面卡号是否隐藏（内置刷卡机型） true: 不隐藏 false: 隐藏
          IsShowDeviceID: false, // 显示本机ID false: 不显示 true: 显示
          CompanyName: '', // 公司名称(长度 64 字节以内)部分版本支持
          StandbyPic: '', // 待机界面图片
          StartingupPic: '', // 开机启动页面图片
          PassableAudio: '', // 允许通行音频
          NoAccessAudio: '', // 紧张通行音频
          StrangerAudio: '' // 陌生人音频
        }, // 人脸设备可视化界面
        systemparameter: {
          Language: 0, // 设备语言，0:英文，1:中文简体，2:中文繁体，3:葡萄牙文，4:韩文，5:俄语（特殊版本支持）,6:意大利语，7：法语，8：西班牙语，9：日语;10:更改设备语言，机器将重启
          DataBaseEnable: false, // 记录抓拍+记录身份证+记录认证（0否 1 是）;默认:true，不开启将不保存抓拍、身份证、认证图片数据
          IDcardtype: 0, // 身份证读卡器类型, 0:中控（IDM30），腾研，华视（CVR-100B），博雅;1:精伦（IDR210）;2:中控（IDM30，IDM10）;3:华视（CVR-100UC）
          FaceDisplay: false, // 是否显示人脸框 0:否 1:是 默认 true
          LiveDetecttype: 0, // 活体检测模式 0: 活体检测一直打开，1：关闭活体检测，2：按时间段打开活体检测
          LiveDetectTimeBeg: '', // 活体检测每天启动时间点 LiveDetectType=2 时必填 (07:00:00)
          LiveDetectTimeEnd: '', // 活体检测每天结束时间点LiveDetectType=2 时必填
          LedLighttype: 0, // 启用白光灯类型 0：从不 1：时间控制 2：光敏控制 3：人脸感应 4：人脸或时间 5 人脸或光感
          LedBrightness: 0, // 当启用白光灯时使用
          LedTimeBeg: '', // 白光灯每天启用开始时间（启用白光灯时间）,当 LedLightType = 1，4时必填(19:00:00)
          LedTimeEnd: '', // 白光灯每天启用结束时间(关闭白光灯时间),当 LedLightType = 1，4 时必填
          LedDisableAfterSec: 0, // 无人多少秒后关闭白光灯（人脸感应）,当 LedLightType = 3,4,5 时必填
          LcdBLDisable: false, // 是否无人时关闭屏幕 0: false 从不， 1:true 未检测到人脸时关闭屏背光
          LcdBLDisableAfterSec: 1, // 无人多少秒后关闭屏幕,当LcdBLDisable=1 时必填
          WebTimeOut: 4 // web 页面登录超时时间（分钟） 2~10 默认:5
        }, // 系统参数下发
        rtspparameter: {
          OpenVerify: false, // 是否启用 RTSP 服务 0：不启用 false 1：启用 true
          PackSize: 10, // 打包大小 byte 1~1500（默认:1500）
          RTSPPort: 6000 // RTSP 端口
        }, // RTSP设置下发
        mqttparameter: {
          facesluiceId: '', // 人脸识别一体机序列号
          MQAddr: '', // MQTT 云地址，<64(特殊版本支持)
          MQPort: 1000, // MQTT 云端口(特殊版本支持)
          MQUser: '', // 云端用户名，<64(特殊版本支持)
          MQPwd: '', // 云端密码，<128(特殊版本支持)
          MQTopic: '', // 设备订阅的话题，<64(特殊版本支持)
          StrangerUploadType: false, // 陌生人抓拍信息上传（默认:0）0：上传 true 1：不上传 false
          RecordUploadtype: 0, // 认证识别信息上传（默认:1）0:不上传1:识别记录上传带抓拍图片2:识别记录上传不抓拍带图片
          Direction: 1, // 一体机进出口方向 0:进口 1:出口 2:无方向
          QRCode: false, // 二维码扫码结果上传（默认:0） 0：不上传 false 1：上传 true
          IDCard: false, // 身份证信息上传（默认:1） 0：不上传 false 1：上传 true
          Card: false, // IC 或 RF 卡号上报（默认:1） 0：不上传 false 1：上传 true
          ResumefromBreakpoint: false, // MQTT 陌生人抓拍信息和识别记录是否开启 断点续传（开启陌生人抓拍上传或者识别记录上传模式下）,0:不开启 false 1:开启 true。
          BeginTime: '' // MQTT 断点续传默认起始时间,可以不填写。默认为设置陌生人抓拍上传或者识别记录上传以及开启断点续传功能的时间。
        }, // MQTT设置下发
        advertisingparameters: {
          facesluiceId: '', // 人脸识别一体机序列号
          addid: '', // 操作 ID 预留
          path: '', // 图片下载路径
          adslot: 1, // 广告槽位 0~4
          polltime: 10 // 每张广告轮询播放时长 默认 10 秒
        } // 广告服务下发
      },
      configFormRules: {},
      device_face_model_name: null,
      device_face_serial: null // 设备序列号
    }
  },
  created () {
    this.fnGetMediaServerList()
    this.fnGetFaceDeviceDetail()
  },
  mounted () { },
  computed: {
    ...mapState(['queryObj', 'queryStatus']),
    ...mapGetters(['isAdmin', 'getOptions', 'getWayName'])
  },
  watch: {},
  methods: {
    ...mapMutations(['setQueryStatus']),
    defaultForm () {
      this.$emit('closeDialog', false)
    },
    // 获取流媒体服务器列表
    async fnGetMediaServerList () {
      var media = await getMediaServerList()
      this.mediaServerList = media && media.Code === 200 ? media.Data : []
    },
    // 获取设备配置详情
    async fnGetFaceDeviceDetail () {
      const res = await getFaceDeviceDetail({
        face_id: this.id
      })
      console.log('获取人脸设备详情--', res.Data)
      if (res && res.Code === 200) {
        this.device_face_model_name = res.Data.device_face_model_name
        this.fnGetFaceDeviceOpenTypeByModelNamel(res.Data.device_face_model_name, res.Data.OpenDoorConfig.OpendoorWay)
        this.configForm = res.Data
        if (res.Data.ConfigShow.Check_Reg_msg === 'null') this.configForm.ConfigShow.Check_Reg_msg = ' '
        if (res.Data.ConfigShow.Check_black_msg === 'null') this.configForm.ConfigShow.Check_black_msg = ' '
        if (res.Data.ConfigShow.Check_true_msg === 'null') this.configForm.ConfigShow.Check_true_msg = ' '
        if (res.Data.ConfigShow.CompanyName === 'null') this.configForm.ConfigShow.CompanyName = ' '
        // this.configForm.ip_address = res.Data.ip_address;
        // this.configForm.DHCP_State = res.Data.DHCP_State;
        this.device_face_serial = res.Data.device_face_serial
        if (res.Data.mqttparameter.BeginTime) this.configForm.mqttparameter.BeginTime = this.$moment(res.Data.mqttparameter.BeginTime).format('YYYY-MM-DD HH:mm:ss')
        this.configForm.OpenDoorConfig.IsMaskOK = res.Data.OpenDoorConfig.IsMaskOK === 1
        this.configForm.ConfigShow.is_Show_IC = !res.Data.ConfigShow.is_Show_IC
        this.configForm.ConfigShow.IsShowIPaddr = !res.Data.ConfigShow.IsShowIPaddr
        // if (res.Data.OpenDoorConfig) this.configForm.OpenDoorConfig = res.Data.OpenDoorConfig;
        // if (res.Data.IdentificationArea) this.configForm.IdentificationArea = res.Data.IdentificationArea;
        // if (res.Data.ConfigShow) this.configForm.ConfigShow = res.Data.ConfigShow;
        // this.configForm.rtspurl = res.Data.rtspurl;
        // this.configForm.MediaServerId = res.Data.MediaServerId;
        // if (res.Data.ConfigShow.PassableAudio) this.PassableAudioName = this.setAudioName(res.Data.ConfigShow.PassableAudio)
        // if (res.Data.ConfigShow.NoAccessAudio) this.NoAccessAudioName = this.setAudioName(res.Data.ConfigShow.NoAccessAudio)
        // if (res.Data.ConfigShow.StrangerAudio) this.StrangerAudioName = this.setAudioName(res.Data.ConfigShow.StrangerAudio)
      }
    },
    OpendoorWaySelectChange (type) {
      this.fnGetFaceDeviceOpenTypeByModelNamel(this.device_face_model_name, type)
    },
    // 获取开门条件下拉列表
    async fnGetFaceDeviceOpenTypeByModelNamel (model, opentype) {
      const res = await getFaceDeviceOpenTypeByModelNamel({
        model_name: model,
        opentype: opentype
      })
      this.openTypeList = res && res.Code === 200 && res.Data ? res.Data[0].child : []
    },

    // 待机界面
    async fnUploadStandbyPic (event) {
      console.log('fnUploadStandbyPic----', event)
      var formData = new FormData()
      formData.append('file', event.file)
      const res = await uploadImage(formData)
      console.log(res)
      if (res.data && res.data.length > 0) {
        this.configForm.ConfigShow.StandbyPic = res.data[res.data.length - 1].FilePath
      }
    },
    // 开机启动页上传
    async fnUploadStartingupPic (event) {
      var formData = new FormData()
      formData.append('file', event.file)
      const res = await uploadImage(formData)
      if (res.data && res.data.length > 0) {
        this.configForm.ConfigShow.StartingupPic = res.data[res.data.length - 1].FilePath
      }
    },
    // 上传广告地址
    async fnUploadAdvertising (event) {
      var formData = new FormData()
      formData.append('file', event.file)
      const res = await uploadImage(formData)
      if (res.data && res.data.length > 0) {
        this.configForm.advertisingparameters.path = res.data[res.data.length - 1].FilePath
      }
    },
    // 处理上传音频 --- 允许通行
    async fnUploadPassableAudio (event) {
      var formData = new FormData()
      console.log('------ event.file-----------', event.file)
      formData.append('file', event.file)
      formData.append('_filename', 'video')
      const res = await uploadFile(formData)
      if (res.data && res.data.length > 0) {
        this.configForm.ConfigShow.PassableAudio = res.data[res.data.length - 1].FilePath
        this.PassableAudioName = event.file.name
      }
    },
    // 处理上传音频 --- 禁止通行
    async fnUploadNoAccessAudio (event) {
      var formData = new FormData()
      formData.append('file', event.file)
      formData.append('_filename', 'video')
      const res = await uploadFile(formData)
      if (res.data && res.data.length > 0) {
        this.configForm.ConfigShow.NoAccessAudio = res.data[res.data.length - 1].FilePath
        this.NoAccessAudioName = event.file.name
      }
    },
    // 处理上传音频 --- 陌生人
    async fnUploadStrangerAudio (event) {
      var formData = new FormData()
      formData.append('file', event.file)
      formData.append('_filename', 'video')
      const res = await uploadFile(formData)
      if (res.data && res.data.length > 0) {
        this.configForm.ConfigShow.StrangerAudio = res.data[res.data.length - 1].FilePath
        this.StrangerAudioName = event.file.name
      }
    },
    // 开门请求
    async fnOpenFaceDevice () {
      const res = await openFaceDevice({ deviceid: this.device_face_serial })
      console.log(res)
      if (res && res.Code === 200) {
        this.$message.success('门已打开')
      }
    },
    // 重启设备
    async fnResetFaceDevice () {
      this.$confirm('是否立即重启设备？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await resetFaceDevice({ deviceid: this.device_face_serial })
        if (res && res.Code === 200) {
          this.$message.success('设备已重启')
        }
      }).catch(() => {

      })
    },
    // 刷新设备配置
    async fnRefreshDeviceConfig () {
      const res = await refreshDeviceConfig({ device_id: this.device_face_serial })
      if (res && res.Code === 200) {
        this.fnGetFaceDeviceDetail()
      }
    },
    // 保存配置
    async saveConfig () {
      var obj = {
        ip_address: this.configForm.ip_address,
        DHCP_State: this.configForm.DHCP_State,
        rtspurl: this.configForm.rtspurl,
        MediaServerId: this.configForm.MediaServerId,
        // IdentificationArea: this.configForm.IdentificationArea,
        ConfigShow: this.configForm.ConfigShow,
        OpenDoorConfig: this.configForm.OpenDoorConfig,
        systemparameter: this.configForm.systemparameter,
        rtspparameter: this.configForm.rtspparameter,
        mqttparameter: this.configForm.mqttparameter,
        advertisingparameters: this.configForm.advertisingparameters,
        IdentificationArea: {}
      }
      obj.OpenDoorConfig.IsMaskOK = this.configForm.OpenDoorConfig.IsMaskOK ? 1 : 0
      obj.ConfigShow.is_Show_IC = !this.configForm.ConfigShow.is_Show_IC
      obj.ConfigShow.IsShowIPaddr = !this.configForm.ConfigShow.IsShowIPaddr
      if (this.configForm.mqttparameter.BeginTime) {
        obj.mqttparameter.BeginTime = this.$moment(this.configForm.mqttparameter.BeginTime).format('YYYY-MM-DDTHH:mm:ss')
      }

      if (this.configForm.OpenDoorConfig.Keeptime && this.configForm.OpenDoorConfig.Keeptime !== 0) obj.OpenDoorConfig.Keeptime = obj.OpenDoorConfig.Keeptim = this.configForm.OpenDoorConfig.Keeptime
      if (this.configForm.IdentificationArea.LeftCornerX && this.configForm.IdentificationArea.LeftCornerX !== 0) obj.IdentificationArea.LeftCornerX = this.configForm.IdentificationArea.LeftCornerX
      if (this.configForm.IdentificationArea.LeftCornerY && this.configForm.IdentificationArea.LeftCornerY !== 0) obj.IdentificationArea.LeftCornerY = this.configForm.IdentificationArea.LeftCornerY
      if (this.configForm.IdentificationArea.DetectWidth && this.configForm.IdentificationArea.DetectWidth !== 0) obj.IdentificationArea.DetectWidth = this.configForm.IdentificationArea.DetectWidth
      if (this.configForm.IdentificationArea.DetectHeight && this.configForm.IdentificationArea.DetectHeight !== 0) obj.IdentificationArea.DetectHeight = this.configForm.IdentificationArea.DetectHeight
      if (this.configForm.IdentificationArea.FaceMinipiXel && this.configForm.IdentificationArea.FaceMinipiXel !== 0) obj.IdentificationArea.FaceMinipiXel = this.configForm.IdentificationArea.FaceMinipiXel
      // 未填写 的 默认值
      if (!this.configForm.systemparameter.WebTimeOut) obj.systemparameter.WebTimeOut = 4
      if (!this.configForm.mqttparameter.MQPort) obj.mqttparameter.MQPort = 0
      obj._id = this.id
      const res = await setDeviceConfig(obj)
      if (res && res.Code === 200) {
        this.$emit('closeDialog', false)
      }
    }
  }
}
</script>
<style scoped lang="less">
.title {
  width: 100%;
  text-align: left;
  font-size: 15px;
  font-weight: 700;
  color: #000;
}

.el-tabs {
  /deep/ .el-tabs__content {
    padding-top: 20px;
    overflow: inherit !important;
  }

  /deep/ .el-tabs__item {
    font-size: 18px !important;
    font-weight: 700 !important;
  }

  /deep/ .el-tabs__nav-wrap::after {
    height: 1.5px;
    background-color: #f2f2f2;
  }

  /deep/ .el-tabs__active-bar {
    height: 1.5px;
  }

  /deep/ .el-tabs__item {
    border-right: none !important;
  }
}

.config_box {
  /deep/ .el-form-item__label {
    width: 220px !important;
  }

  /deep/ .el-form-item__content {
    margin-left: 220px !important;
    display: flex;
    align-items: center;
    width: 54%;

    .el-radio__label {
      display: none;
    }

    // .el-select,
    // .el-input {
    //   width: 500px;
    // }
    .el-input,
    .el-select {
      width: 100%;
    }
  }

  /deep/ .flex-box {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    max-height: 580px;
    overflow-y: auto;

    .right,
    .left {
      width: 45%;
    }

    .right {
      margin-left: 5%;
    }
  }

  /deep/ .face_box {
    .el-select,
    .el-input {
      width: 400px;
      margin-right: 10px;
    }
  }
}

.audio_box {
  display: flex;
  align-items: center;

  .el-input {
    width: 300px !important;
    margin-right: 15px;
  }
}

.box-card {
  position: relative;
  padding-bottom: 30px;

  .checkButton {
    position: absolute;
    right: 0px;
  }

  .checkButton2 {
    position: absolute;
    right: 100px;
  }

  .checkButton3 {
    position: absolute;
    right: 200px;
  }
}</style>
