<template>
  <!-- 新增对话框 -->
  <el-dialog :close-on-click-modal="false" append-to-body title="恢复出厂" :visible.sync="isResetDialog" width="45%" @close="closeHandle">
    <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
    <div style="margin: 15px 0;"></div>
    <el-checkbox-group v-model="checkedItemList" @change="handleCheckedCitiesChange">
      <el-checkbox v-for="item in itemList" :key="item.val" :label="item.val">{{item.label}}</el-checkbox>
    </el-checkbox-group>
    <span class="tip">提示：当选择“名称及ip地址”、“抓拍记录”、“名单登录记录”、“控制记录”、“RTSP”进行恢复出厂时，设备将重启</span>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="fnFactoryResetFaceDevice">立即恢复出厂</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
import { getFaceDeviceDetail, getFaceDeviceOpenTypeByModelNamel, uploadImage, uploadFile, setDeviceConfig, openFaceDevice, resetFaceDevice, factoryResetFaceDevice } from '@/api/securityApi'
export default {
  props: ['device_face_serial'],
  data () {
    return {
      isResetDialog: true,
      checkAll: false,
      checkedItemList: [],
      itemList: [
        { val: 'DefaltDoorSet', checked: false, label: '开门条件' },
        { val: 'DefaltSoundSet', checked: false, label: '声音设置' },
        { val: 'DefaltNetPar', checked: false, label: '网络参数' },
        { val: 'DefaltCenterPar', checked: false, label: '中心连接参数' },
        { val: 'DefaltCapture', checked: false, label: '抓拍记录 + 控制记录' },
        { val: 'DefaltLog', checked: false, label: '日志记录' },
        { val: 'DefaltPerson', checked: false, label: '人员名单库 + 控制记录' },
        { val: 'DefaltRecord', checked: false, label: '控制记录' },
        { val: 'DefaltMaintainTime', checked: false, label: '系统维护时间' },
        { val: 'DefaltSystemSettings', checked: false, label: '系统参数' },
        { val: 'Mqtt', checked: false, label: 'mqtt服务' },
        { val: 'Rtsp', checked: false, label: 'rtsp服务' },
        { val: 'Http', checked: false, label: 'http服务' }
      ],
      isIndeterminate: false
    }
  },
  created () { },
  mounted () { },
  computed: {},
  watch: {},
  methods: {
    // 全选
    handleCheckAllChange (val) {
      this.checkedItemList = []
      if (val) {
        this.itemList.forEach(item => {
          this.checkedItemList.push(item.val)
        })
      }
      this.isIndeterminate = false
    },
    // 单次选中
    handleCheckedCitiesChange (value) {
      var checkedCount = value.length
      this.checkAll = checkedCount === this.itemList.length
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.itemList.length
    },
    // 恢复出厂设备
    async fnFactoryResetFaceDevice () {
      if (this.checkedItemList.length === 0) return this.$message.warning('请选择要恢复出厂的设置项')
      this.$confirm('是否立即恢复出厂？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        var obj = {}
        obj.deviceid = this.device_face_serial
        this.checkedItemList.forEach(item => {
          obj[item] = true
        })
        const res = await factoryResetFaceDevice(obj)
        if (res && res.Code === 200) {
          this.$message.success('已恢复出厂设备')
          this.isResetDialog = false
          this.$emit('close')
        }
      }).catch(() => {

      })
    },
    closeHandle () {
      this.$emit('close')
    }
  }
}
</script>
<style scoped lang="less">
.tip {
  color: red;
}
.el-checkbox-group {
  .el-checkbox {
    width: 33%;
    margin-right: 0px;
    margin-bottom: 10px;
  }
}
</style>
